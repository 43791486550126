<template>
  <router-link to="/">
    <v-avatar rounded="" size="" width="100vw" height="100vh">
      <img
        src="https://www.wpbeginner.com/wp-content/uploads/2013/04/wp404error.jpg"
        alt=""
      />
    </v-avatar>
    ></router-link
  >
</template>

<script>
export default {};
</script>

<style></style>
